/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { ConsignmentNoteDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class ConsignmentNoteDataAccessService {
	private api = inject(ApiService);

	getAll(query: EntityQueryDto<ConsignmentNoteDto>) {
		return this.api.post<ConsignmentNoteDto[]>(`consignmentNotes.getAll`, { body: { data: query } });
	}

	getById(id: number, query?: FindOptionsDto<ConsignmentNoteDto>) {
		return this.api.post<ConsignmentNoteDto>(`consignmentNotes.getById`, { body: { data: { id, ...query } } });
	}
}
