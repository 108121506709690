/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { ApiService } from '@nest/common/frontend/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { OutboundDeliveryOrderLineDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class OutboundDeliveryOrderLineDataAccessService {
	private api = inject(ApiService);

	// GET @ outbound/delivery/orders/lines/
	getAll(query: EntityQueryDto<OutboundDeliveryOrderLineDto>) {
		return this.api.post<OutboundDeliveryOrderLineDto[]>(`outboundDeliveryOrderLines.getAll`, { body: { data: query } });
	}

	// GET @ outbound/delivery/orders/lines/:id
	getById(id: number, query: FindOptionsDto<OutboundDeliveryOrderLineDto>) {
		return this.api.post<OutboundDeliveryOrderLineDto>(`outboundDeliveryOrderLines.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<OutboundDeliveryOrderLineDto>) {
		return this.api.post<number>(`outboundDeliveryOrderLines.count`, { body: { data: query } });
	}
}
