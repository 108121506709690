import { Currency, DeliveryType, EntityIdDto, PaymentType } from '@nest/common/core/shared';
import { PackageUnitMatch } from '@nest/domain/delivery/shared';
import { PackageUnitDto, StockItemDto } from '@nest/domain/entities-metadata/dtos';
export interface OutboundDeliveryOrderCreateData {
	organization: EntityIdDto;
	warehouse: EntityIdDto;
	buyer: EntityIdDto;
	documentType: EntityIdDto;
	outboundDocument?: EntityIdDto;
	inboundDocument?: EntityIdDto;
	requestDate: Date;
	currency: Currency;
	recipientTotalPrice?: number;
	recipient?: EntityIdDto;
	// recipientSnapshot: PartySnapshot;
	recipientSnapshot?: any;
	buyerNote?: string;
	recipientNote?: string;
	isDropshipping: boolean;
	deliveryType: DeliveryType;
	paymentType: PaymentType;
	carrier?: EntityIdDto;
	buyerOrderNumber?: string;
	foreignOrderNumber?: string;
	variableSymbol?: string;
	lines: OutboundDeliveryOrderCreateLineData[];
}

export interface PackageUnitMatchStockItem {
	packageUnit: PackageUnitDto;
	matchedStockItems: StockItemDto[];
	remainingStockItems: StockItemDto[];
	match: PackageUnitMatch;
}

export interface PackageUnitAtLocation {
	packageUnit: PackageUnitDto;
	stockItems: StockItemDto[];
	matchedStockItems: StockItemDto[];
	remainingStockItems: StockItemDto[];
	match: PackageUnitMatch;
}

export interface ScanAndPackageUnitAtLocation {
	scannedStockItems: StockItemDto[];
	match: PackageUnitAtLocation;
	partialMatches: PackageUnitAtLocation[];
	knownStockItem: StockItemDto;
	isKnownStockItemCode: boolean;
}

export interface OutboundDeliveryOrderCreateLineData {
	item: EntityIdDto;
	quantity: number;
	note?: string;
	inboundDocumentLine?: EntityIdDto;
	outboundDocumentLine?: EntityIdDto;
}

// omit: organizationId, warehouseId, outboundDocumentId
export interface OutboundDeliveryOrderUpdateData extends OutboundDeliveryOrderCreateData {
	id: number;
	lines: OutboundDeliveryOrderUpdateLineData[];
}

export interface OutboundDeliveryOrderUpdateLineData extends OutboundDeliveryOrderCreateLineData {
	id: number;
}

export class OutboundDeliveryOrderCreatedJob {
	outboundDeliveryOrderId!: number;

	singletonKey() {
		return this.outboundDeliveryOrderId.toString();
	}
}

export class CreateOutboundDeliveryOrdersShipmentJob {
	outboundDeliveryOrderIds!: number[];
}

export class OnOutboundDocumentCompletedJob {
	outboundDocumentId!: number;

	singletonKey() {
		return this.outboundDocumentId.toString();
	}
}

export interface PrintPackageLabelsBody {
	// @IsNotEmpty()
	printerId: number;
}

export interface PrintOutboundDispatchAdviceBody {
	// @IsNotEmpty()
	printerId: number;

	// @IsArray()
	lineIds: number[];

	numberOfCopies: number;
	// @Type(() => Boolean)
	preview?: boolean;
}

export enum OutboundDeliveryOrderState {
	Created = 'Created',
	ToProcess = 'ToProcess',
	InProgress = 'InProgress',
	Dispatched = 'Dispatched',
	Canceled = 'Canceled'
}
