import { defineEntityGetByIdProcedure, defineProcedure, defineRouter } from '@nest/common/rpc/shared';
import { ConsignmentNoteDto, OutboundDeliveryOrderDocumentTypeDto, OutboundDeliveryOrderDto, OutboundQueueDto, StockItemDto } from '@nest/domain/entities-metadata/dtos';
import { entities } from '@nest/domain/entities/declarations';
import { type } from '@nest/utils/shared';
import { z } from 'zod';

export const wmsOutboundRouter = defineRouter({
	outboundQueues: {
		getAllForMe: defineProcedure({
			input: z.object({
				filter: z.any().optional()
			}),
			output: type<OutboundQueueDto[]>()
		})
	},
	outboundDeliveryOrderDocumentTypes: {
		getById: defineEntityGetByIdProcedure(entities.outboundDeliveryOrderDocumentTypes),
		getAllForMe: defineProcedure({
			input: z.object({
				options: z.any().optional(),
				filter: z.any().optional()
			}),
			output: type<OutboundDeliveryOrderDocumentTypeDto[]>()
		})
	},
	outboundDeliveryOrders: {
		countByDocumentType: defineProcedure({
			input: z.object({
				documentTypeId: z.number()
			}),
			output: type<number>()
		}),
		countByQueue: defineProcedure({
			input: z.object({
				queueId: z.number()
			}),
			output: type<number>()
		}),
		getAllByQueue: defineProcedure({
			input: z.object({
				queueId: z.number(),
				query: z.any().optional()
			}),
			output: type<OutboundDeliveryOrderDto[]>()
		}),
		toProcess: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		cancel: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		createShipment: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		printPackageLabels: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		})
		// printOutboundDispatchAdvice: defineProcedure({
		// 	input: z.coerce.number(),
		// 	output: type<void>()
		// })
	},
	outboundDeliveryOrderProcess: {
		findStockItemByCode: defineProcedure({
			input: z.object({
				code: z.string()
			}),
			output: type<StockItemDto>()
		}),
		start: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		leave: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		startLine: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		leaveLine: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		}),
		getWavelessRemainingStocksToPick: defineProcedure({
			input: z.coerce.number(),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		suggestWavelessPickingNextPickLocations: defineProcedure({
			input: z.coerce.number(),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessSuggestNextPick: defineProcedure({
			input: z.coerce.number(),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessLeavePick: defineProcedure({
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessLeaveDocumentType: defineProcedure({
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessScanStockItem: defineProcedure({
			input: z.object({ code: z.string() }),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessScanLocationHandlingUnit: defineProcedure({
			input: z.object({ code: z.string() }),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessLoadPick: defineProcedure({
			input: z.object({
				documentTypeId: z.coerce.number(),
				stockItemId: z.coerce.number(),
				storeLocationId: z.coerce.number(),
				sourceHandlingUnitId: z.coerce.number().optional(),
				pickingHandlingUnitId: z.coerce.number().optional(),
				quantity: z.coerce.number()
			}),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessUnload: defineProcedure({
			input: z.object({
				documentTypeId: z.coerce.number(),
				destionationLocationId: z.coerce.number()
			}),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		wavelessAssignNextPickToPicker: defineProcedure({
			input: z.object({
				documentTypeId: z.coerce.number(),
				locationId: z.coerce.number()
			}),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		scanStockItem: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				code: z.string()
			}),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		scanHandlingUnit: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				code: z.string()
			}),
			output: type<any>() // Replace 'any' with the actual return type
		}),
		load: defineProcedure({
			input: z.object({
				outboundDeliveryOrderLineId: z.coerce.number(),
				stockItemId: z.coerce.number(),
				sourceLocationId: z.coerce.number(),
				sourceHandlingUnitId: z.coerce.number().optional(),
				quantity: z.coerce.number()
			}),
			output: type<void>()
		}),
		unloadAll: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				destinationLocationId: z.coerce.number()
			}),
			output: type<void>()
		}),
		manualPackConfirm: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				sourceStorageLocationId: z.coerce.number(),
				packageUnitDrafts: z.any() // Replace 'any' with the actual type
			}),
			output: type<void>()
		}),
		confirmShipmentPack: defineProcedure({
			input: z.object({
				id: z.coerce.number(),
				sourceStorageLocationId: z.coerce.number()
			}),
			output: type<void>()
		}),
		createShipment: defineProcedure({
			input: z.coerce.number(),
			output: type<void>()
		})
	},
	shipment: {
		getShipmentRouteItemsToLoad: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number()
			}),
			output: type<{ shipmentStopId: number; quantityToLoad: number; stockItem: StockItemDto }[]>()
		}),
		getShipmentRouteOneItemToLoad: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number(),
				shipmentStopId: z.number(),
				stockItemId: z.number()
			}),
			output: type<{ shipmentStopId: number; quantityToLoad: number; stockItem: StockItemDto }>()
		}),
		confirmItemToLoad: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number(),
				shipmentStopId: z.number(),
				stockItemId: z.number(),
				sourceLocationId: z.number(),
				quantity: z.number()
			}),
			output: type<void>()
		}),
		startShipmentRouteLoading: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number()
			}),
			output: type<{ id: number }>()
		}),
		confirmShipmentRouteLoading: defineProcedure({
			input: z.object({
				shipmentRouteId: z.number()
			}),
			output: type<void>()
		})
	},
	consignmentNote: {
		getById: defineEntityGetByIdProcedure(entities.consignmentNotes),
		create: defineProcedure({
			input: z.object({
				containerNumber: z.string(),
				carrierId: z.number()
			}),
			output: type<ConsignmentNoteDto>()
		}),
		addPackageUnitByCode: defineProcedure({
			input: z.object({
				consignmentNoteId: z.number(),
				code: z.string()
			}),
			output: type<void>()
		}),
		complete: defineProcedure({
			input: z.object({
				consignmentNoteId: z.number()
			}),
			output: type<void>()
		}),
		removePackageUnit: defineProcedure({
			input: z.object({
				consignmentNoteId: z.number(),
				packageUnitId: z.number()
			}),
			output: type<void>()
		}),
		printConsignmentNote: defineProcedure({
			input: z.object({
				printerId: z.number().optional(),
				numberOfCopies: z.number().optional(),
				consignmentNoteId: z.coerce.number()
			}),
			output: type<any>()
		})
	}
});
