/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { OutboundDeliveryOrderDto } from '@nest/domain/entities-metadata/dtos';
import { OutboundDeliveryOrderCreateData, OutboundDeliveryOrderUpdateData, PrintOutboundDispatchAdviceBody, PrintPackageLabelsBody } from '@nest/domain/wms-outbound/shared';

@Injectable({
	providedIn: 'root'
})
export class OutboundDeliveryOrderDataAccessService {
	private api = inject(ApiService);

	// GET @ outbound/delivery/orders/
	getAll(query: EntityQueryDto<OutboundDeliveryOrderDto>) {
		return this.api.post<OutboundDeliveryOrderDto[]>(`outboundDeliveryOrders.getAll`, { body: { data: query } });
	}

	// GET @ outbound/delivery/orders/queues/:queueId
	getAllByQueue(queueId: number, query: EntityQueryDto<OutboundDeliveryOrderDto>) {
		return this.api.post<OutboundDeliveryOrderDto[]>(`outboundDeliveryOrders.getAllByQueue`, { body: { data: { queueId, query } } });
	}

	// GET @ outbound/delivery/orders/count
	count(query: EntityQueryDto<OutboundDeliveryOrderDto>) {
		return this.api.post<number>(`outboundDeliveryOrders.count`, { body: { data: query } });
	}

	// GET @ outbound/delivery/orders/queues/:queueId/count
	countByQueue(queueId: number) {
		return this.api.get<number>(`outbound/delivery/orders/queues/${queueId}/count`);
	}

	// GET @ outbound/delivery/orders/document-types/:documentTypeId/count
	countByDocumentType(id: number) {
		return this.api.get<number>(`outbound/delivery/orders/document-types/${id}/count`);
	}

	// GET @ outbound/delivery/orders/:id
	getById(id: number, query: FindOptionsDto<OutboundDeliveryOrderDto>) {
		return this.api.post<OutboundDeliveryOrderDto>(`outboundDeliveryOrders.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	// POST @ outbound/delivery/orders/
	create(body: OutboundDeliveryOrderCreateData) {
		return this.api.post<OutboundDeliveryOrderDto>(`outbound/delivery/orders/`, { body });
	}

	// PUT @ outbound/delivery/orders/:id?
	update(id: number, body: OutboundDeliveryOrderUpdateData) {
		return this.api.put<OutboundDeliveryOrderDto>(`outbound/delivery/orders/${id}?`, { body });
	}

	// POST @ outbound/delivery/orders/:id/cancel
	cancel(id: number) {
		return this.api.post<OutboundDeliveryOrderDto>(`outbound/delivery/orders/${id}/cancel`, {});
	}

	// POST @ outbound/delivery/orders/:id/to-process
	toProcess(id: number) {
		return this.api.post<OutboundDeliveryOrderDto>(`outbound/delivery/orders/${id}/to-process`, {});
	}

	createShipment(id: number) {
		return this.api.post<void>(`outbound/delivery/orders/${id}/create-shipment`, {});
	}

	// POST @ outbound/delivery/orders/:id/print-package-labels
	printPackageLabels(id: number, body: PrintPackageLabelsBody) {
		return this.api.post<void>(`outbound/delivery/orders/${id}/print-package-labels`, { body });
	}

	// POST @ outbound/delivery/orders/:id/print-outobund-dispatch-advice
	printOutboundDispatchAdvice(id: number, body: PrintOutboundDispatchAdviceBody) {
		return this.api.post<{ data: any; documentName: string } | any>(`outbound/delivery/orders/${id}/print-outobund-dispatch-advice`, { body });
	}
}
