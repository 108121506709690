/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { ConsignmentNoteLineDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class ConsignmentNoteLineDataAccessService {
	private api = inject(ApiService);

	getAll(query: EntityQueryDto<ConsignmentNoteLineDto>) {
		return this.api.post<ConsignmentNoteLineDto[]>(`consignmentNoteLines.getAll`, { body: { data: query } });
	}

	getById(id: number, query?: FindOptionsDto<ConsignmentNoteLineDto>) {
		return this.api.post<ConsignmentNoteLineDto>(`consignmentNoteLines.getById`, { body: { data: { id, ...query } } });
	}
}
