/** Auto-generated file */
import { inject, Injectable } from '@angular/core';
import { EntityQueryDto, FilterQueryDto, FindOptionsDto } from '@nest/common/core/shared';
import { ApiService } from '@nest/common/frontend/core';
import { OutboundDeliveryOrderDocumentTypeDto } from '@nest/domain/entities-metadata/dtos';

@Injectable({
	providedIn: 'root'
})
export class OutboundDeliveryOrderDocumentTypeDataAccessService {
	private api = inject(ApiService);

	// GET @ outbound/delivery/orders/document-types/
	getAll(query: EntityQueryDto<OutboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<OutboundDeliveryOrderDocumentTypeDto[]>(`OutboundDeliveryOrderDocumentType.getAll`, { body: { data: query } });
	}

	// GET @ outbound/delivery/orders/document-types/
	getAllForMe(query: EntityQueryDto<OutboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<OutboundDeliveryOrderDocumentTypeDto[]>(`outboundDeliveryOrderDocumentTypes.getAllForMe`, { body: { data: query } });
	}

	// GET @ outbound/delivery/orders/document-types/:id
	getById(id: number, query: FindOptionsDto<OutboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<OutboundDeliveryOrderDocumentTypeDto>(`OutboundDeliveryOrderDocumentType.getById`, { body: { data: { id, fields: query?.fields } } });
	}

	count(query: FilterQueryDto<OutboundDeliveryOrderDocumentTypeDto>) {
		return this.api.post<number>(`OutboundDeliveryOrderDocumentType.count`, { body: { data: query } });
	}
}
